/* You can add global styles to this file, and also import other style files */
a { cursor: pointer }

body{
    background-color:#2f2f2f;
}

#sitelogo {
    max-width: 196px;
}

.nav-bar-1{
    width: 40px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 6px;
}


.pac-container {
    z-index: 1051 !important;
}

.subscribe_ft{
    background-color: #4d4c4c;
    border: 1px solid #858383;
    margin-bottom: 0.5rem;
    border-radius: 0px;
}

.nav-bar-2{
    width: 32px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 6px;
}

.nav-bar-3{
    width: 25px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 0px;
}

.navbarSmallDiveces {
    position: fixed;
    box-sizing: border-box;
    width: 320px;
    height: 100%;
    background-color: #222;
    z-index: 9999;
    top: 0;
    padding: 1rem;
    right: 0;
}

.navbarSmallDiveces a {
    color: #ffffff;
    text-decoration: none;
}


.navbarSmallDiveces .nav-link {
    padding-right: 0;
    padding-left: 0;
    text-transform: uppercase;
}

.nav-hide{
    margin-right: -320px;
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
}

.nav-show{
    margin-right: 0px;
    transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
}

.moble-nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border: 0;
    
}

#mobile-navigation-close {
    position: absolute;
    right: 10px;
    z-index: 99999;
}

#mobile-navigation-close .nav-bar-1 {
    width: 16px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 0px;
    transform: rotate(31deg);
    -webkit-transform: rotate(31deg);
    transition:all 0.9s;
    -webkit-transition:all 0.9s;
}

#mobile-navigation-close .nav-bar-3 {
    width: 16px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 0px;
    transform: rotate(145deg);
    -webkit-transform: rotate(145deg);
    transition: all 0.9s;
    -webkit-transition: all 0.9s;
}

#mobile-navigation-close .nav-bar-2 {
    width: 32px;
    height: 4px;
    background-color: #fff;
    display: block;
    margin-bottom: 1px;
}

.justify-end{
    justify-content: flex-end;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(251 251 251);
}

.cu_btn {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-transform: uppercase;
    border-radius: 0px;
}

.logo_cu{
    max-width: 277px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2{
    font-weight: 700;
    font-size: 2rem;
}

.btn{
        text-transform: uppercase;
        font-weight: 600;
    }

 .card {
        box-shadow: 0px 1px 4px #0005;
        -webkit-box-shadow: 0px 1px 4px #0004;
    }

    

    .set_outline {
        outline: 2px solid #ffc10780;
        outline-offset: -15px;
    }


    .main_banner_row {
        min-height: 100vh;
        background-size: cover;
        background-attachment: fixed;
    }

    h2>span{
        color:#ffc107;
    }

    
.light-text{
    font-weight: 300;
}

.nav-item>a.nav-link:hover {
    transition: all 0.4s ease;
    color: #ffc107 !important;
}

.bg-dark {
    background-color: #212529!important;
}


select option{
    color:#222222 !important;
}

.defualt_bg {
    background-image: url(/assets/images/love-outline-dark.jpg);
    background-size: 400px;
    background-attachment: fixed;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.bar_bottom::after {
    content: '';
    width: 113px;
    height: 7px;
    position: relative;
    background: #fffc00;
    background: linear-gradient(to bottom, #ffffff, #fffc00);
    display: block;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
}


.page-link {
    z-index: 2;
    color: #dfe4e8;
    text-decoration: none;
    background-color: #3d4c5c;
    border-color: #151a1f;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ffc107;
    border-color: #3d4c5c;
}


.page-link:hover {
    z-index: 2;
    color: #dfe4e8;
    text-decoration: none;
    background-color: #3d4c5c;
    border-color: #151a1f;
}

.community_section {
    background: #000000;
    background-image: url(/assets/images/comunity.jpg);
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
    min-height: 446px;
}

.join_community_wrapper{
    margin-top: 7rem;
}

.join_community_wrapper .cu_btn{
    margin-top: 2rem;
}

.mob-nav-notification{
    display: none;
}

.m-view-icon {
    display:none !important;
}

.desktop-view{
    display: inherit;
}

.user_profile_ls {
    width: 61px;
    height: 61px;
    border-radius: 65px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 9px;
    border: 3px solid #989797;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: #c7c7c7;
}

.f-align-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bullet-none{
    list-style-type: none;
}

h5>a {
    color: #f2f2f2;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 1.2rem;
}

h5>a:hover{
    color: #f2f2f2;
    text-decoration: none;
    }

.m_text{
    font-size: 0.9rem;
}

.m-view{
    display: none !important;
}

.swinger-mobile-navigation{
    display:none;
}

.user_profile_ls img{
    height: 63px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.user_profile_ls.active {
    border: 3px solid #00922c;
}

.right_navigation_panel .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.5rem;
}

.right_navigation_panel .card-title {
    margin-bottom: 0.02rem;
    font-size: 0.9rem;
}

.right_navigation_panel .text-muted {
    color: #6c757d!important;
    font-size: 0.7rem !important;
}

.right_navigation_panel h4 {
    font-size: 1.05rem;
}

.over_flow_wrapper{
    max-height: 600px;
    overflow: auto;
    overflow-x: hidden;
}

.over_flow_wrapper_big {
    max-height: 2000px;
    overflow: hidden;
    overflow-x: hidden;
}


.small-text{
    font-size: 0.8rem;
    color: #999;
    margin-top: 0.3rem;
}

label {
    display: inline-block;
    margin-bottom: .3rem;
    font-size: 0.9rem !important;
}

.describe{
    height: 200px !important;
    resize: none;
}

textarea{
    height: 100px;
    resize: none;
}


.modal.show {
    display: block !important;
    min-width:100vh;
    overflow-y: auto;
}

.modal.hide{
    display: none !important;
}


.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #212529;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.matched-status {
    width: 71px;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 68px;
    margin-top: 15px;
   
}

.hoverPointer:hover{
    cursor: pointer;
    color:#555;
}

.not_matched{
    background-color: #9e4941;
    border: 9px solid #7e1e1e;
}

.matched{
    background-color: #419e56;
    border: 9px solid #1e7e34;
}

.ative_el{
    background-color: #419e56 !important;
    color:#ffffff  !important;
}

.heading_al{
    background-color: #121212;
    padding: 5px 0px 5px 0px;
    font-size: 1.2rem !important;
}

.image-wrapper{
    position: relative;
    overflow: hidden;
}

.image-wrapper img {
    height: 120px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.image-wrapper:hover img {
    height: 120px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

.lb-data .lb-closeContainer {
    float: right;
    position: absolute;
    top: -13px;
}

.overlay-video::before {
 content: '';
 width: 100%;
 height: 100%;
 background-color:#fff;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    text-transform: capitalize;
}


video{
    background-color: #000000;
}

.top_wrapper {
    position: absolute;
    width: 100%;
    z-index: 999;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.privacy-status {
    position: absolute;
    width: 20px;
    right: 4px;
    font-size: 0.8rem;
}

.video_views_wrapper{
    font-size: 0.8rem;
    position: absolute;
    left: 0.5rem;
}

.video_views_wrapper span{
    margin-right: 10px;
}


.edit_mode_item_r{
    width: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 3px;
    font-size: 0.8rem;
}

.around_space{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.like:hover{
    color:rgb(9, 149, 9);
}

.ac_vt:hover{
    color:rgb(34, 219, 96) !important;
}


.edit_mode_wrapper {
    display: flex;
    padding-right: 0.3rem;
    font-size: 0.8rem;
    width: 100%;
    position: absolute;
    padding-left: 0.2rem;
}

.staus_grow{
    right: 8px;
    position: absolute;
    top: 4px;
}

.staus_grow_delete{
    position: absolute;
    right: 34px;
    top: 3px;
}

.fullCalender{
    background: #212529;
    color: #ffffff;
    padding: 1rem;
    margin-bottom: 2rem;
}

.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    color: #f2f2f2;
}

.fc .fc-daygrid-day-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.fc .fc-daygrid-day-number {
    padding: 4px;
    position: relative;
    z-index: 4;
    color: #f2f2f2;
}

.fc .fc-daygrid-day-number:hover {
    text-decoration: none;
}


.fc .fc-daygrid-day-number {
    padding: 4px;
    padding-top: 0.8rem;
    position: relative;
    z-index: 4;
    font-size: 1.2rem;
}

.fc-event-title .fc-sticky{
    text-transform: capitalize;
}

.fc-daygrid-day-events .fc-daygrid-event-harness:nth-child(1) a{
    background-color: #00922c;
    border: 1px solid #00922c;
}

.fc-daygrid-day-events .fc-daygrid-event-harness:nth-child(3) a{
    background-color: #ffc107;
    border: 1px solid #ffc107;
}

.fc-daygrid-day-events .fc-daygrid-event-harness:nth-child(4) a{
    background-color: #c3e6cb;
    border: 1px solid #c3e6cb;
}

.fc-daygrid-day-events .fc-daygrid-event-harness:nth-child(4) a{
    background-color: #9e4941;
    border: 1px solid #9e4941;
}

.modal-header-event {
    height: 200px;
    background-size: 100%;
    background-position: top;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.modal-header-event::before {
    content: '';
    width: 100%;
    background-color: #0606064d;
    height: 100%;
    z-index: 1;
    position: absolute;
    overflow: hidden;
}

.z_2{
    z-index: 2;
}
.mt_2{
    margin-top: 3em;
   }
.mt_1{
 margin-top: 1em;
}

.mb_1{
 margin-bottom: 1em;
}
.mb_2{
    margin-bottom: 2em;
   }
.pt_1{
    margin-top: 1em;
   }
   
   .pb_1{
    margin-bottom: 1em;
   }

   .pb_2{
    margin-bottom: 2em;
   }
.modal-header-event .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    width: 20px;
    position: absolute;
    top: 0;
    right: 8px;
    border: 0px;
    z-index: 999;
}

.navbar-nav .active{
    color:#ffc107;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 45%;
}


.owl-theme .owl-nav [class*=owl-]:hover {
    background: #ffc107;
    color: #FFF;
    text-decoration: none;
}

.owl-next{
    margin-right: -11px  !important;
}

.owl-prev{
    margin-left: -11px  !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffc107 !important;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .5rem;
    margin-left: -1px;
    line-height: 1.25;
}


.see_more_wrapper{
    width: 100%; 
    text-align: center;
    text-align:right;
}

.see_more_wrapper a{
    color:#f2f2f2 ;
    text-decoration:none;
}

.border-top-1{
        border-top: 1px solid #444;
    }

.native-fullscreen .singleVideo {
        height: 100% !important;
    }
    
.count-progressbar{
        position: absolute;
        margin-left: 42%;
        margin-top: 8px;
    }
    
    







    